<template>
  <add-edit-property-component :is-edit="true" />
</template>

<script>
// eslint-disable-next-line import/extensions
import AddEditPropertyComponent from '@/views/pages/properties/AddEditPropertyComponent'
import { mapActions } from 'vuex'

export default {
  name: 'EditProperty',
  components: { AddEditPropertyComponent },
  async created() {
    await this.loadPropertyData()
  },
  methods: {
    ...mapActions({
      get: 'get',
    }),
    async loadPropertyData() {
      await this.get({
        url: `/admin/properties/${this.$route.params.id}`,
        storeName: 'properties',
        commitUrl: 'properties/SET_PROPERTY',
      })
    },
  },
}
</script>

<style scoped>

</style>
